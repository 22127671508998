@use "../abstracts" as *;

.authors {
  display: flex;
  flex-wrap: wrap;
  gap: $space-1 $space-1_5;
  margin-bottom: $space-5;

  @include media-breakpoint-up(md) {
    margin-bottom: $space-6;
  }

  @include media-breakpoint-up(xl) {
    column-gap: $space-4;
  }

  .page-opulent__head & {
    --color-meta-data-bullets: var(--color-text-invert);

    justify-content: center;
    margin-bottom: $space-6;
    text-shadow: $text-shadow-small;

    @include media-breakpoint-up(xl) {
      margin-bottom: $space-8;
    }
  }

  .article--geo & {
    --color-meta-data-bullets: var(--color-text-highlight-geo);
    --list-link-hover-color: var(--color-text-highlight-geo);
    --original-source-border-color: var(--color-border-geo-highlight);
  }

  .article--capital & {
    --color-meta-data-bullets: var(--color-text-highlight-capital);
    --list-link-hover-color: var(--color-text-highlight-capital);
    --original-source-border-color: var(--color-border-capital-highlight);
  }

  &__brand-identifier {
    align-self: center;
    height: $space-2;

    @include media-breakpoint-up(lg) {
      height: $space-2_5;
    }
  }

  &__list-item {
    display: inline;
  }

  &__list-link {
    color: currentColor;
    text-decoration: none;

    &:hover {
      color: var(--list-link-hover-color, var(--color-text-highlight));
    }
  }

  &__meta-data {
    width: 100%;

    .page-opulent & {
      display: flex;
      justify-content: center;
    }

    @include media-breakpoint-up(md) {
      width: inherit;
    }
  }

  &__meta-data-item {
    display: inline;

    &:not(:last-child)::after {
      color: var(--color-meta-data-bullets, var(--color-text-highlight));
      content: "\2022";
      margin: 0 $space-0_5;
    }
  }

  &__original-source {
    background-color: var(--color-bg-subtle);
    border-left: 2px solid
      var(--original-source-border-color, var(--color-text-highlight));
    display: inline-flex;
    padding: $space-0_5 $space-1_5;
  }

  &__shortcode {
    color: var(--color-text-muted);
  }
}
