@use "../abstracts" as *;
@use "../foundation/icons";

.group-teaserblock {
  /* Default values: used for 'standard-s' and legacy teaserblocks with a mismatched viewType. */
  --group-teaserblock-grid-template-columns: repeat(
    auto-fill,
    minmax(min(230px, 100%), 1fr)
  );
  --group-teaserblock-gap: #{$space-4} #{$space-3};
  --group-teaserblock-padding-block: #{$space-4};
  --group-teaserblock-padding-inline: #{$space-px-3};
  --group-teaserblock-items-padding-top: #{$space-3};
  --group-teaserblock-box-shadow: #{$content-shadow};

  background-color: var(--color-bg-secondary);
  box-shadow: var(--group-teaserblock-box-shadow);
  margin-bottom: var(--group-teaserblock-margin-bottom, $space-1_5);
  position: relative;

  &:not(:has(.group-teaserblock__header)) {
    --group-teaserblock-items-padding-top: var(
      --group-teaserblock-padding-block
    );
  }

  &__header {
    display: grid;
    grid-template-rows: 1fr auto;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &__header-image {
    grid-area: 1 / 1 / 2 / 2;
    position: relative;

    @include media-breakpoint-up(md) {
      grid-row-end: 3;
    }

    &::after {
      --group-teaserblock-bg-ending-shape: circle;

      background: linear-gradient(
          rgba(0, 0, 0, 0) 40%,
          rgba(var(--group-teaserblock-bg-rgb), 1) 100%
        ),
        radial-gradient(
          var(--group-teaserblock-bg-ending-shape) at top right,
          rgba(var(--group-teaserblock-bg-rgb), 0) 50%,
          rgba(var(--group-teaserblock-bg-rgb), 0.6) 100%
        ),
        linear-gradient(
          rgba(0, 0, 0, 0.05) 25%,
          rgba(0, 0, 0, 0.1) 50%,
          rgba(0, 0, 0, 0.6) 100%
        );
      content: "";
      inset: 0;
      position: absolute;

      @include media-breakpoint-up(md) {
        --group-teaserblock-bg-ending-shape: ellipse;
      }
    }
  }

  &__image {
    aspect-ratio: 1;

    > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      aspect-ratio: 1.7778;
    }

    @include media-breakpoint-up(lg) {
      aspect-ratio: 2;
      display: flex;
    }
  }

  &__header-content-1 {
    grid-area: 1 / 1 / 2 / 2;
    padding: var(--group-teaserblock-padding-block)
      var(--group-teaserblock-padding-inline) 0;
    text-align: var(--group-teaserblock-header-text-align);

    .group-teaserblock--with-large-header & {
      padding-top: $space-6;

      @include media-breakpoint-up(md) {
        padding-top: $space-10;
      }
    }

    .group-teaserblock--with-background-image & {
      align-self: end;
      z-index: 1;

      &:not(:has(+ .group-teaserblock__header-content-2)) {
        padding-bottom: $space-4;

        @include media-breakpoint-up(md) {
          padding-bottom: $space-5;
        }
      }
    }
  }

  &__header-content-2 {
    grid-area: 2 / 1 / 3 / 2;
    padding: 0 var(--group-teaserblock-padding-inline);
    text-align: var(--group-teaserblock-header-text-align);
    z-index: 1;

    .group-teaserblock--with-background-image
      &:not(:has(.group-teaserblock__navigation)) {
      padding-bottom: $space-2;

      @include media-breakpoint-up(md) {
        padding-bottom: $space-4;
      }
    }
  }

  &__marketing {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space-2_5;

    .ad-sponsors,
    .ad-labels {
      display: flex;
      flex-wrap: wrap;
    }

    > .ad-labels {
      margin-right: $space-1;
    }
  }

  &__brand-identifier {
    display: block;
    height: rem(36px); // Without a headline the logo is bigger.
    margin: 0 auto $space-1_5;

    &:has(+ .group-teaserblock__headline) {
      height: rem(23px); // With a headline the logo is smaller.
    }

    @include media-breakpoint-up(md) {
      height: rem(60px);

      &:has(+ .group-teaserblock__headline) {
        height: rem(29px);
      }
    }

    .group-teaserblock--with-background-image & {
      filter: drop-shadow($text-shadow-small);
    }
  }

  &__headline {
    margin-bottom: $space-1;
    text-wrap: balance;

    @include media-breakpoint-up(md) {
      margin-bottom: $space-2;
    }

    .group-teaserblock--with-background-image & {
      text-shadow: $text-shadow-large;
    }
  }

  &__headline-image {
    display: inline-block;
    height: clamp(rem(20px), 0.75em, rem(40px));
    margin-right: 0.04em;
  }

  &__headline-link {
    &::after {
      @extend .icon-element;
      content: $icon-arrow-right;
      color: var(--color-text-muted);
      display: inline-block;
      font-size: rem(16px);
      margin-left: $space-1;
      transition: all 0.3s ease;
      transform: translateY(-0.1em);

      .group-teaserblock--with-large-header &,
      .group-teaserblock--with-background-image & {
        font-size: rem(24px);
        margin-left: $space-1_5;

        @include media-breakpoint-up(md) {
          transform: translateY(-0.3em);
        }
      }
    }

    &:hover::after {
      color: var(--color-text-highlight);
    }
  }

  &__intro {
    margin-bottom: $space-1;
    margin-inline: var(--group-teaserblock-intro-margin-inline);
    max-width: rem(680px);

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-2;
    }

    .group-teaserblock--with-large-header & {
      max-width: rem(568px);
    }

    .group-teaserblock--with-background-image & {
      max-width: rem(480px);
      text-shadow: $text-shadow-small;
    }
  }

  &__navigation {
    margin: $space-1_5 auto $space-2;
    max-width: var(--group-teaserblock-navigation-max-width);
    width: var(--group-teaserblock-navigation-width);

    @include media-breakpoint-up(md) {
      margin-top: $space-2;
    }
  }

  &__items {
    display: grid;
    grid-template-columns: var(--group-teaserblock-grid-template-columns);
    gap: var(--group-teaserblock-gap);
    padding: var(--group-teaserblock-items-padding-top)
      var(--group-teaserblock-padding-inline)
      var(--group-teaserblock-padding-block);

    &:not(:has(> :first-child)) {
      padding-bottom: 0;
    }
  }

  /* Order: breakpoint overrides for default values must be placed before the values for modifier. */
  @include media-breakpoint-up(md) {
    --group-teaserblock-gap: #{$space-5} #{$space-4};
    --group-teaserblock-margin-bottom: #{$space-3};
    --group-teaserblock-padding-block: #{$space-5};
    --group-teaserblock-padding-inline: #{$space-px-5};
  }

  @include media-breakpoint-up(lg) {
    --group-teaserblock-gap: #{$space-6} #{$space-5};
    --group-teaserblock-padding-block: #{$space-8};
    --group-teaserblock-padding-inline: #{$space-px-10};
  }

  &--opulent-l {
    --group-teaserblock-grid-template-columns: 1fr;
    --group-teaserblock-gap: 0;
    --group-teaserblock-padding-block: 0;
    --group-teaserblock-padding-inline: 0;
    --group-teaserblock-items-padding-top: 0;
  }

  &--list {
    --group-teaserblock-grid-template-columns: 1fr;
  }

  &--with-large-header {
    --group-teaserblock-items-padding-top: #{$space-4};
  }

  &--with-large-header,
  &--with-background-image {
    --group-teaserblock-header-text-align: center;
    --group-teaserblock-intro-margin-inline: auto;
    --group-teaserblock-navigation-max-width: 680px;
    --group-teaserblock-navigation-width: fit-content;

    @include media-breakpoint-up(md) {
      --group-teaserblock-items-padding-top: #{$space-5};
    }
  }

  &--with-background-image {
    background-color: rgb(var(--group-teaserblock-bg-rgb));
    color: var(--color-text-invert);
  }

  /* If the bookmarklist is placed in another teaserblock, all outer spacing and shadows should be removed. */
  & &--is-bookmarklist {
    --group-teaserblock-box-shadow: none;
    --group-teaserblock-items-padding-top: 0;
    --group-teaserblock-margin-bottom: 0;
    --group-teaserblock-padding-block: 0;
    --group-teaserblock-padding-inline: 0;
  }
}

[class*="group-teaserblock--standard-xs"],
[class*="group-teaserblock--standard-m"] {
  --group-teaserblock-grid-template-columns: repeat(
    auto-fill,
    minmax(min(320px, 100%), 1fr)
  );
}

/* The first, first two or first three element(s) spans the entire width. */
[class*="group-teaserblock--standard-"][class*="-fw-1x"]
  .group-teaserblock__items
  > :first-child,
[class*="group-teaserblock--standard-"][class*="-fw-2x"]
  .group-teaserblock__items
  > :nth-child(-n + 2),
[class*="group-teaserblock--standard-"][class*="-fw-3x"]
  .group-teaserblock__items
  > :nth-child(-n + 3) {
  grid-column: 1 / -1;
}
