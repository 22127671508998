@use "../abstracts" as *;

.media {
  --media-padding-inline: 0;

  padding-inline: 0;
  margin-block: $space-5;

  @include media-breakpoint-up(md) {
    --media-padding-inline: var(--article-padding-inline);

    margin-block: $space-6;
  }

  @include media-breakpoint-up(lg) {
    margin-block: $space-8;
  }

  &__caption {
    display: flex;
    flex-direction: column;
    gap: $space-0_5;
    margin: $space-1 0;

    @include media-breakpoint-up(xl) {
      margin: $space-1_5 0;
    }
  }

  &__credits,
  &__duration {
    color: var(--color-text-moderate);
  }

  &--lead-content {
    margin-block: 0;
    padding-inline: 0;

    @include media-breakpoint-up(md) {
      padding-inline: var(--media-padding-inline);
    }

    .media__caption {
      column-gap: var(--article-column-gap);
      display: grid;
      grid-template-columns: var(--article-grid-template-columns);
      margin: $space-1_5 0 0;
      padding-inline: $space-3;

      @include media-breakpoint-up(md) {
        padding-inline: 0;
      }

      @include media-breakpoint-up(lg) {
        margin: $space-2 0 0;
      }
    }

    :is(.media__description, .media__credits) {
      grid-column: var(--article-body-grid-column);
    }
  }

  &__player {
    position: relative;

    .media--video & {
      aspect-ratio: 16 / 9;
    }

    figure {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    figcaption {
      left: -999em;
      position: absolute;
      top: -999em;
    }

    .iframe-element__iframe {
      overflow: hidden;
    }

    &--no-access {
      cursor: pointer;

      &--video {
        &::before {
          background: url(@client/images/video-player-placeholder.webp)
            center/contain no-repeat;
          content: "";
          inset: 0;
          position: absolute;
          z-index: 1;
        }
      }

      &--audio {
        aspect-ratio: 21 / 3.85;

        &::before {
          background: url(@client/images/audio-player-placeholder.webp)
            center/contain no-repeat;
          content: "";
          inset: 0;
          position: absolute;
          z-index: 1;
        }
      }

      .image-element {
        img {
          width: 100%;
        }
      }
    }
  }
}
