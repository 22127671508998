@use "../abstracts" as *;

.iframe-element {
  &__iframe {
    border: none;
    width: 100%;

    [style*="aspect-ratio"] > & {
      height: 100%;
    }
  }

  .article__body > & {
    margin-block: $space-5;

    @include media-breakpoint-up(md) {
      margin-bottom: $space-6;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-8;
    }
  }
}
