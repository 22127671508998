@use "../abstracts" as *;

/* The sorting of the tokens should correspond to the Figma file for the design system. */

// Headline ‒ Semantic Tokens

.typo-headline1 {
  font-weight: $font-weight-700;
  font-size: $font-size-32;
  line-height: $line-height-120;

  @include media-breakpoint-up(md) {
    font-size: $font-size-40;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-44;
  }

  @include media-breakpoint-up(xl) {
    font-size: $font-size-60;
  }
}

.typo-headline2 {
  font-weight: $font-weight-700;
  font-size: $font-size-26;
  line-height: $line-height-120;
  letter-spacing: $letter-spacing-loose;

  @include media-breakpoint-up(md) {
    font-size: $font-size-28;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-32;
  }
}

.typo-headline3 {
  font-weight: $font-weight-700;
  font-size: $font-size-20;
  line-height: $line-height-120;

  @include media-breakpoint-up(md) {
    font-size: $font-size-22;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-24;
  }
}

.typo-headline4 {
  font-weight: $font-weight-700;
  font-size: $font-size-18;
  line-height: $line-height-120;

  @include media-breakpoint-up(md) {
    font-size: $font-size-20;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-22;
  }
}

.typo-article-kicker {
  font-weight: $font-weight-700;
  font-size: $font-size-14;
  line-height: $line-height-120;
  letter-spacing: $letter-spacing-loose;
  text-transform: $text-transform-uppercase;

  @include media-breakpoint-up(md) {
    font-size: $font-size-16;
  }
}

.typo-opulent-short {
  font-weight: $font-weight-700;
  font-size: $font-size-32;
  line-height: $line-height-120;

  @include media-breakpoint-up(md) {
    font-size: $font-size-36;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-40;
  }

  @include media-breakpoint-up(xl) {
    font-size: $font-size-44;
  }
}

.typo-opulent-long {
  font-weight: $font-weight-700;
  font-size: $font-size-24;
  line-height: $line-height-120;

  @include media-breakpoint-up(md) {
    font-size: $font-size-28;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-32;
  }

  @include media-breakpoint-up(xl) {
    font-size: $font-size-36;
  }
}

// Paragraph ‒ Semantic Tokens

.typo-intro {
  font-weight: $font-weight-500;
  font-size: $font-size-17;
  line-height: $line-height-160;

  @include media-breakpoint-up(md) {
    font-size: $font-size-18;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-20;
  }
}

.typo-body-default {
  font-size: $font-size-17;
  line-height: $line-height-160;

  @include media-breakpoint-up(md) {
    font-size: $font-size-18;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-20;
  }

  &-underlined {
    @extend .typo-body-default;
    text-decoration: underline;
  }

  &-bold {
    @extend .typo-body-default;
    font-weight: $font-weight-700;
  }

  &-compact {
    @extend .typo-body-default;
    line-height: $line-height-150;
  }

  &-bold-compact {
    @extend .typo-body-default;
    font-weight: $font-weight-700;
    line-height: $line-height-150;
  }
}

.typo-body-medium {
  font-size: $font-size-15;
  line-height: $line-height-150;

  @include media-breakpoint-up(md) {
    font-size: $font-size-16;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-17;
  }
}

.typo-body-small {
  font-size: $font-size-15;
  line-height: $line-height-150;
}

.typo-caption {
  font-weight: $font-weight-500;
  font-size: $font-size-13;
  line-height: $line-height-150;

  @include media-breakpoint-up(md) {
    font-size: $font-size-14;
  }
}

.typo-quote {
  font-weight: $font-weight-800;
  font-size: $font-size-26;
  line-height: $line-height-120;
  letter-spacing: $letter-spacing-loose;

  @include media-breakpoint-up(md) {
    font-size: $font-size-32;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-40;
  }
}

// Info ‒ Semantic Tokens

.typo-breadcrumb {
  font-weight: $font-weight-400;
  font-size: $font-size-10;
  line-height: $line-height-130;
  letter-spacing: $letter-spacing-loose;
  text-decoration: none;

  @include media-breakpoint-up(md) {
    font-size: $font-size-12;
  }
}

.typo-info-head {
  font-weight: $font-weight-700;
  font-size: $font-size-22;
  line-height: $line-height-130;

  @include media-breakpoint-up(md) {
    font-size: $font-size-24;
  }
}

.typo-info-subhead {
  font-weight: $font-weight-700;
  font-size: $font-size-20;
  line-height: $line-height-130;
}

.typo-info {
  font-size: $font-size-16;
  line-height: $line-height-130;

  &-bold {
    @extend .typo-info;
    font-weight: $font-weight-500;
  }
}

.typo-info-small {
  font-size: $font-size-14;
  line-height: $line-height-130;

  &-bold {
    @extend .typo-info-small;
    font-weight: $font-weight-500;
  }
}

.typo-article-info {
  font-size: $font-size-14;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-16;
  }

  &-bold {
    @extend .typo-article-info;
    font-weight: $font-weight-500;
  }
}

.typo-credit {
  font-size: $font-size-12;
  letter-spacing: $letter-spacing-loose;
}

.typo-topic-tag {
  font-size: $font-size-12;
  line-height: $line-height-130;
  letter-spacing: $letter-spacing-loose;
  text-decoration: none;

  /* The individual tags have a background color. For monitors with a
  devicePixelRatio of 1, the regular line-height of 1.3 leads to an unclean shift
  in the baseline. That's why we set a line-height of 1rem as an exception, which
  converts to a full pixel value. */
  @media (-webkit-device-pixel-ratio: 1) {
    line-height: $space-2;
  }
}

.typo-seo-tag {
  font-size: $font-size-17;
  letter-spacing: $letter-spacing-loose;
}

.typo-seo-tag-head {
  font-weight: $font-weight-700;
  font-size: $font-size-20;
  line-height: $line-height-160;
}

.typo-ad-label {
  font-size: $font-size-12;
  letter-spacing: $letter-spacing-loose;
  color: var(--color-text-muted);
}

.typo-overlay-head {
  font-weight: $font-weight-500;
  font-size: $font-size-20;
  line-height: $line-height-120;
}

// Navigation ‒ Semantic Tokens

.typo-navi-primary {
  font-weight: $font-weight-500;
  font-size: $font-size-16;
  line-height: $line-height-120;
  text-decoration: none;
}

.typo-navi-secondary {
  font-weight: $font-weight-400;
  font-size: $font-size-16;
  line-height: $line-height-120;
  text-decoration: none;
}

.typo-navi-tertiary {
  font-weight: $font-weight-500;
  font-size: $font-size-14;
  line-height: $line-height-130;
  text-decoration: none;
}

.typo-navi-section {
  font-weight: $font-weight-700;
  font-size: $font-size-16;
  line-height: $line-height-150;
  text-transform: uppercase;
}

.typo-navi-tags {
  font-size: $font-size-15;
  line-height: $line-height-110;
  text-decoration: none;
}

// Forms - Semantic Tokens

.typo-button-label {
  font-weight: $font-weight-500;
  font-size: $font-size-14;
  line-height: $line-height-120;
  text-decoration: none;
}

.typo-button-label-large {
  font-weight: $font-weight-700;
  font-size: $font-size-16;
  line-height: $line-height-120;
  text-decoration: none;
}

// Teasers - Semantic Tokens

.typo-teaser-standard-l-head {
  font-weight: $font-weight-700;
  font-size: $font-size-24;
  line-height: $line-height-130;

  @include media-breakpoint-up(md) {
    font-size: $font-size-28;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-32;
  }
}

.typo-teaser-standard-m-head,
.typo-teaser-list-head {
  font-weight: $font-weight-700;
  font-size: $font-size-22;
  line-height: $line-height-130;
}

.typo-teaser-standard-s-head {
  font-weight: $font-weight-700;
  font-size: $font-size-20;
  line-height: $line-height-130;
  letter-spacing: $letter-spacing-loose;
}

.typo-teaser-standard-xs-head {
  font-weight: $font-weight-700;
  font-size: $font-size-16;
  line-height: $line-height-130;
  letter-spacing: $letter-spacing-loose;

  @include media-breakpoint-up(lg) {
    font-size: $font-size-18;
  }
}

.typo-teaser-opulent-l-head {
  font-weight: $font-weight-700;
  font-size: $font-size-26;
  line-height: $line-height-120;

  @include media-breakpoint-up(md) {
    font-size: $font-size-28;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-32;
  }
}

.typo-teaser-opulent-m-head {
  font-weight: $font-weight-700;
  font-size: $font-size-22;
  line-height: $line-height-120;
}

.typo-teaser-kicker-medium {
  font-weight: $font-weight-700;
  font-size: $font-size-14;
  line-height: $line-height-120;
  letter-spacing: $letter-spacing-loose;
  text-transform: $text-transform-uppercase;
}

.typo-teaser-kicker-small {
  font-weight: $font-weight-700;
  font-size: $font-size-13;
  line-height: $line-height-120;
  letter-spacing: $letter-spacing-loose;
  text-transform: $text-transform-uppercase;
}

.typo-teaser-info-medium {
  font-size: $font-size-14;
  letter-spacing: $letter-spacing-loose;
}

.typo-teaser-info-small {
  font-size: $font-size-13;
}

.typo-teaser-intro {
  font-size: $font-size-15;
  line-height: $line-height-150;
}

.typo-teaserblock-intro {
  font-size: $font-size-15;
  line-height: $line-height-150;
}

.typo-teaserblock-head {
  font-weight: $font-weight-700;
  font-size: $font-size-26;
  line-height: $line-height-130;
  letter-spacing: $letter-spacing-loose;

  @include media-breakpoint-up(md) {
    font-size: $font-size-28;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-32;
  }
}

.typo-header-index-head {
  font-weight: $font-weight-700;
  font-size: $font-size-40;
  line-height: $line-height-120;

  @include media-breakpoint-up(md) {
    font-size: $font-size-56;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-60;
  }
}

.typo-header-subressort-head {
  font-weight: $font-weight-700;
  font-size: $font-size-32;
  line-height: $line-height-120;

  @include media-breakpoint-up(md) {
    font-size: $font-size-44;
  }
}

.typo-teaserblock-opulent-head {
  font-weight: $font-weight-800;
  font-stretch: $font-stretch-80;
  font-size: $font-size-44;
  line-height: $line-height-100;
  letter-spacing: $letter-spacing-loose;

  @include media-breakpoint-up(md) {
    font-size: $font-size-48;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-56;
  }
}

.typo-teaser-embed-head {
  font-weight: $font-weight-700;
  font-size: $font-size-16;
  line-height: $line-height-130;
  letter-spacing: $letter-spacing-loose;

  @include media-breakpoint-up(md) {
    font-size: $font-size-26;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-28;
  }
}

.typo-teaser-affiliate-head {
  font-weight: $font-weight-700;
  font-size: $font-size-20;
  line-height: $line-height-130;
}

.typo-teaser-affiliate-info-bold {
  font-weight: $font-weight-700;
  font-size: $font-size-20;
  line-height: $line-height-130;

  @include media-breakpoint-up(md) {
    font-size: $font-size-22;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-24;
  }
}

.typo-teaser-affiliate-info {
  font-weight: $font-weight-400;
  font-size: $font-size-20;
  line-height: $line-height-130;

  @include media-breakpoint-up(md) {
    font-size: $font-size-22;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-24;
  }
}

// Gallery - Semantic Tokens

.typo-gallery-head {
  font-weight: $font-weight-700;
  font-size: $font-size-26;
  line-height: $line-height-130;
  letter-spacing: $letter-spacing-loose;
}

.typo-gallery-caption {
  font-size: $font-size-15;
  line-height: $line-height-150;
}

.typo-gallery-caption-head {
  font-weight: $font-weight-700;
  font-size: $font-size-15;
  line-height: $line-height-150;
}
