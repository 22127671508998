@use "../abstracts" as *;

.widget-element {
  overflow: hidden;
  position: relative;

  iframe {
    max-width: 100%;
  }

  .article__body > & {
    margin-block: $space-5;

    @include media-breakpoint-up(md) {
      margin-bottom: $space-6;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: $space-8;
    }

    &--consent {
      display: flex;
      background: var(--color-bg-subtle, #f3f3f3);
      flex-direction: column;
      gap: $space-3;
      justify-content: flex-start;
      margin-inline: auto;
      padding: $space-px-3 $space-px-2_5;

      @include media-breakpoint-up(md) {
        padding: $space-px-5 $space-px-4;
      }

      @include media-breakpoint-up(lg) {
        padding: $space-px-6 $space-px-5;
      }

      @include media-breakpoint-up(lg) {
        padding: $space-px-5;
      }

      & button {
        border-radius: 2px;
        border: 1px solid var(--color-border-default, #bbb);
        cursor: pointer;
        height: $space-px-6;
        padding: $space-px-1 $space-px-2_5;
        width: fit-content;
      }
    }
  }
}
