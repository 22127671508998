@use "../abstracts" as *;

.brand-identifier {
  aspect-ratio: var(--brand-identifier-aspect-ratio);
  background: var(--brand-identifier-background-image) center / 100% no-repeat;
  display: inline-flex;

  &--capital {
    --brand-identifier-aspect-ratio: var(--capital-logo-aspect-ratio);
    --brand-identifier-background-image: var(--capital-logo);
  }

  &--capital-white {
    --brand-identifier-aspect-ratio: var(--capital-logo-aspect-ratio);
    --brand-identifier-background-image: var(--capital-white-logo);
  }

  &--geo {
    --brand-identifier-aspect-ratio: var(--geo-logo-aspect-ratio);
    --brand-identifier-background-image: var(--geo-logo);
  }

  &--geo-white {
    --brand-identifier-aspect-ratio: var(--geo-logo-aspect-ratio);
    --brand-identifier-background-image: var(--geo-white-logo);
  }

  &--stern {
    --brand-identifier-aspect-ratio: var(--stern-logo-aspect-ratio);
    --brand-identifier-background-image: var(--stern-logo);
  }

  &--str_crime {
    --brand-identifier-aspect-ratio: var(--str_crime-logo-aspect-ratio);
    --brand-identifier-background-image: var(--str_crime-logo);
  }

  &--str_plus {
    --brand-identifier-aspect-ratio: var(--str_plus-logo-aspect-ratio);
    --brand-identifier-background-image: var(--str_plus-logo);
  }

  &--str_crime-white {
    --brand-identifier-aspect-ratio: var(--str_crime-logo-aspect-ratio);
    --brand-identifier-background-image: var(--str_crime-white-logo);
  }
}
