/* DIMENSIONS */

/* There are no semantic tokens for spacings. They can be used directly
  in components, partials and pages. */

$space-1: 0.5rem; // .5rem | 8px
$space-0_5: $space-1 * 0.5; // .25rem | 4px
$space-1_5: $space-1 * 1.5; // .75rem | 12px
$space-2: $space-1 * 2; // 1rem | 16px
$space-2_5: $space-1 * 2.5; // 1.25rem | 20px
$space-3: $space-1 * 3; // 1.5rem | 24px
$space-4: $space-1 * 4; // 2rem | 32px
$space-5: $space-1 * 5; // 2.5rem | 40px
$space-6: $space-1 * 6; // 3rem | 48px
$space-7: $space-1 * 7; // 3.5rem | 56px
$space-8: $space-1 * 8; // 4rem | 64px
$space-9: $space-1 * 9; // 4.5rem | 72px
$space-10: $space-1 * 10; // 5rem | 80px
$space-12: $space-1 * 12; // 6rem | 96px
$space-15: $space-1 * 15; // 7.5rem | 120px
$space-16: $space-1 * 16; // 8rem | 128px
$space-20: $space-1 * 20; // 10rem | 160px

/* In some cases it may be better to use pixel values, such as in layout grids.
  They should not be scaled with the font size setting in the browser. */
$space-px-1: 8px;
$space-px-0_5: $space-px-1 * 0.5; // 4px
$space-px-1_5: $space-px-1 * 1.5; // 12px
$space-px-2: $space-px-1 * 2; // 16px
$space-px-2_5: $space-px-1 * 2.5; // 20px
$space-px-3: $space-px-1 * 3; // 24px
$space-px-4: $space-px-1 * 4; // 32px
$space-px-5: $space-px-1 * 5; // 40px
$space-px-6: $space-px-1 * 6; // 48px
$space-px-7: $space-px-1 * 7; // 56px
$space-px-8: $space-px-1 * 8; // 64px
$space-px-9: $space-px-1 * 9; // 72px
$space-px-10: $space-px-1 * 10; // 80px
$space-px-12: $space-px-1 * 12; // 96px
$space-px-14: $space-px-1 * 14; // 112px

/* Border tokens */
$border-radius-low: 0.125rem;

/* Layout related tokens */
$max-content-width: 1024px;
$header-height: 8.875rem; // 142px
$header-height-on-xl: 9.875rem; // 158px

/* Ad Container Widths */
/* Skyscaper (in px) */
$ad-skyscraper-height: 600px;
$ad-skyscraper-width: 160px;
