@use "../abstracts" as *;
@use "../foundation/icons";

.paid-barrier {
  --paid-barrier-padding: #{$space-4} #{$space-2};

  align-items: center;
  background: var(--color-bg-subtle);
  color: var(--color-text-default);
  display: flex;
  flex-direction: column;
  gap: $space-3;
  margin: 0 calc(-1 * var(--article-column-gap))
    var(--paid-barrier-margin-bottom, $space-10);
  padding: var(--paid-barrier-padding);
  position: relative;
  text-align: center;

  @include media-breakpoint-up(md) {
    --paid-barrier-margin-bottom: calc(2 * #{$space-12});
    --paid-barrier-padding: #{$space-5} #{rem(92px)}; // 96px is a magic number taken from the design
    --paid-barrier-shadow-height: #{$space-12};
  }

  @include media-breakpoint-up(lg) {
    --paid-barrier-padding: #{$space-5} #{rem(110px)}; // 110px is a magic number taken from the design
  }

  .dialog & {
    --paid-barrier-padding: #{$space-8} #{$space-3} #{$space-4};

    background: var(--color-bg-default);
    margin: 0;

    @include media-breakpoint-up(md) {
      --paid-barrier-padding: #{$space-8} #{rem(88px)} #{$space-5};
    }

    &::after {
      content: none;
    }
  }

  &::before {
    background: linear-gradient(
      180deg,
      var(--color-fade-paywall-end) 0%,
      var(--color-fade-paywall-middle) 40%,
      var(--color-fade-paywall-start) 90%
    );
    content: "";
    height: var(--paid-barrier-shadow-height, #{$space-10});
    inset: auto 0 100%;
    position: absolute;
  }

  &__icon {
    display: inline-flex;
    width: $space-7;
  }

  &_headline {
    text-wrap: balance;
  }

  &_benefits {
    display: flex;
    flex-direction: column;
    gap: $space-1;
    margin-top: $space-2;
    text-align: left;
  }

  &_benefit {
    display: flex;
    align-items: center;
    gap: $space-1;

    i:not([class]) {
      display: contents;
    }
  }

  &_benefit-icon {
    display: inline-flex;
    font-size: $font-size-24;
  }

  &_button {
    align-content: center;
    background: var(--paid-barrier-button-bg, var(--color-bg-highlight));
    border-radius: 0.125rem;
    color: var(--color-text-invert);
    min-height: $space-6;
    padding: $space-1_5 $space-2_5;
    transition: background var(--default-transition-time);
    width: 100%;

    &:hover {
      --paid-barrier-button-bg: var(--color-bg-highlight-emphasized);
    }

    &:active {
      --paid-barrier-button-bg: var(--color-bg-highlight-muted);
    }
  }

  &_login {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $space-0_5;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &_login-link {
    text-decoration: underline;
    transition: text-decoration-color var(--default-transition-time);

    &:hover {
      text-decoration-color: transparent;
    }
  }
}
