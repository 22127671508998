@use "../abstracts" as *;
@use "../foundation/icons";

.u-blanklist {
  list-style: none;
  margin: 0;
  padding: 0;
}

.u-richtext {
  .article--geo & {
    --color-highlight: var(--color-text-highlight-geo);
  }

  .article--capital & {
    --color-highlight: var(--color-text-highlight-capital);
  }

  strong {
    font-weight: $font-weight-500;
  }

  a {
    color: currentColor;

    &:hover {
      color: var(--color-highlight, var(--color-text-highlight));
    }
  }

  // Presentership affiliate links coming from the feed with the curtain structure:
  // (inside copy text) <span class="presentership--affiliate"><a class="link--external"></a></span>
  // (as standalone button) <span class="presentership--affiliate"><a class="button--cta u-typo u-typo--button-cta button"></a></span>

  .presentership--affiliate .button--cta,
  .link--external {
    &::after {
      @extend .icon-element;
      display: inline-flex;
      font-size: 1em;
      padding-inline-start: 0.25em;
      position: relative;
      text-decoration: none;
    }
  }

  .link--external::after {
    content: $icon-external;
    top: 0.1em; // align with text
  }

  .presentership--affiliate {
    :is(.link--external, .button--cta)::after {
      content: "*";
      font-weight: inherit;
      top: -0.125em;
    }

    .button--cta::after {
      padding-inline-start: $space-1;
    }
  }

  ol,
  ul {
    padding: 0;
    list-style-position: outside;

    li {
      &:not(:last-child) {
        margin-bottom: $space-1_5;
      }
    }

    li::marker {
      color: var(--color-highlight, var(--color-text-highlight));
    }
  }

  ol {
    margin: 0 0 1.25rem $space-px-3;

    li {
      padding-left: $space-1;
    }
  }

  ul {
    margin: 0 0 1.25rem $space-px-2_5;

    li {
      padding-left: $space-1_5;
    }

    li::marker {
      @extend .icon-element;
      content: $icon-arrow-right;
      font-size: 0.8em;
      position: relative;
      top: 0.5em;
    }
  }

  .strike {
    text-decoration: line-through;
  }

  .underline {
    text-decoration: underline;
  }
}

.u-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.d-none {
  display: none !important;
}

.d-lg-none {
  @include media-breakpoint-up(lg) {
    display: none !important;
  }
}

.d-md-revert {
  @include media-breakpoint-up(md) {
    display: revert !important;
  }
}

.d-md-flex {
  @include media-breakpoint-up(md) {
    display: flex !important;
  }
}

.d-lg-block {
  @include media-breakpoint-up(lg) {
    display: block !important;
  }
}

.d-lg-flex {
  @include media-breakpoint-up(lg) {
    display: flex !important;
  }
}

.d-xl-block {
  @include media-breakpoint-up(xl) {
    display: block !important;
  }
}

.justify-content-center {
  justify-content: center !important;
}

.u-button {
  background: var(--u-button-background, var(--color-bg-default));
  border-radius: $space-0_5;
  border: 1px solid var(--u-button-border, var(--color-border-default));
  color: var(--u-button-color, var(--color-text-default));
  cursor: pointer;
  display: inline-flex;
  padding: $space-1_5;
  text-decoration: none;
  transition:
    background-color var(--default-transition-time),
    border-color var(--default-transition-time),
    color var(--default-transition-time);

  &:hover,
  &:focus {
    --u-button-background: var(--color-bg-emphasized-subtle);
  }

  &:active {
    --u-button-background: var(--color-bg-emphasized-moderate);
  }

  &.active {
    --u-button-background: var(--color-bg-invert);
    --u-button-border: var(--color-bg-invert);
    --u-button-color: var(--color-text-invert);
  }
}

.clearfix {
  clear: both;
}
