@use "../abstracts" as *;

.group-inline-gallery {
  background-color: var(--color-bg-invert-soft);
  margin-block: $space-4;

  .article__body & {
    margin-inline: (-$space-px-3);

    @include media-breakpoint-up(md) {
      margin-inline: var(--article-body-reverse-indent-1-column);
    }
  }

  &--index-gallery {
    box-shadow: $content-shadow;
    position: relative;
  }

  &--short-text-gallery {
    background: var(--color-bg-default);

    .article__body & {
      margin-inline: 0;
    }
  }
}
