@use "../abstracts" as *;
@use "../foundation/icons";

.teaser-opulent {
  background-color: rgb(var(--teaser-opulent-bg-rgb));
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: var(--teaser-opulent-grid-template-rows);
  position: relative;

  &__image-wrapper,
  &__image-layer {
    aspect-ratio: var(--teaser-opulent-image-wrapper-aspect-ratio);
    grid-column: 1 / -1;
    grid-row: var(--teaser-opulent-image-wrapper-grid-row);
    overflow: hidden;
  }

  &__image-layer {
    background: var(--teaser-opulent-image-layer-background);
  }

  &__text-layer {
    background: var(
      --teaser-opulent-text-layer-background,
      linear-gradient(rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.5) 100%)
    );
    grid-column: 1 / -1;
    grid-row: var(--teaser-opulent-text-layer-grid-row);
  }

  &__image {
    height: 100%;
    width: 100%;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__badge {
    max-width: max-content;
    position: absolute;
    right: var(--teaser-opulent-badge-right);
    top: var(--teaser-opulent-badge-top);
  }

  &__link {
    color: var(--color-text-invert);
    grid-column: 1 / -1;
    grid-row: var(--teaser-opulent-link-grid-row);
    padding-inline: var(--teaser-opulent-padding-inline);
    text-align: var(--teaser-opulent-text-align);
    text-decoration: none;

    // to make the whole teaser clickable
    &::after {
      position: absolute;
      content: "";
      inset: 0;
    }
  }

  &__headline-wrapper {
    display: flex;
    flex-direction: column;
    gap: $space-1;
    hyphens: auto;
    max-width: 680px;
    word-break: break-word;
    text-wrap: balance;

    @include media-breakpoint-up(md) {
      text-wrap: pretty;
    }
  }

  &__paid-marker {
    transform: translateY(var(--teaser-opulent__paid-marker--translate-y, 0));
    width: var(--teaser-opulent__paid-marker--width);
  }

  &__text {
    color: var(--color-text-invert);
    grid-column: 1 / -1;
    grid-row: var(--teaser-opulent-text-grid-row);
    max-width: 680px;
    padding-block: var(--teaser-opulent-text-padding-block);
    padding-inline: var(--teaser-opulent-padding-inline);
    text-align: var(--teaser-opulent-text-align);
  }

  &__footer {
    grid-column: 1 / -1;
    grid-row: var(--teaser-opulent-footer-grid-row);
    padding-block: var(--teaser-opulent-footer-padding-block);
    padding-inline: var(--teaser-opulent-padding-inline);

    .teaser-footer {
      /* Should always have the same height, regardless of the content. */
      height: rem(36px);

      &__bookmark {
        &:is(button),
        & button {
          background-color: var(--color-bg-invert-emphasized-subtle);
          border-radius: 50%;
          color: var(--color-text-invert);
          padding: $space_1;
          width: rem(36px);
        }

        & button {
          margin-right: $space_2;
        }
      }

      &__author {
        color: var(--color-text-invert-alpha);
      }
    }
  }

  &--opulent-m {
    --teaser-opulent-badge-right: #{$space-px-2};
    --teaser-opulent-badge-top: #{$space-2};
    --teaser-opulent-footer-grid-row: 3 / 4;
    --teaser-opulent-footer-padding-block: #{$space-1_5} #{$space-2_5};
    --teaser-opulent-grid-template-rows: repeat(3, auto);
    --teaser-opulent-image-wrapper-aspect-ratio: 4 / 5;
    --teaser-opulent-image-wrapper-grid-row: 1 / 3;
    --teaser-opulent-link-grid-row: 2 / 3;
    --teaser-opulent-padding-inline: #{$space-px-2_5};
    --teaser-opulent-text-layer-grid-row: 3 / 4;
    --teaser-opulent__paid-marker--width: #{$space-3};

    --teaser-opulent-image-layer-background: linear-gradient(
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(
        rgba(var(--teaser-opulent-bg-rgb), 0) 40%,
        rgba(var(--teaser-opulent-bg-rgb), 1) 100%
      ),
      radial-gradient(
        ellipse at top right,
        rgba(var(--teaser-opulent-bg-rgb), 0) 60%,
        rgba(var(--teaser-opulent-bg-rgb), 1) 100%
      );

    @include media-breakpoint-up(md) {
      --teaser-opulent__paid-marker--width: #{$space-3};
    }
  }

  &--opulent-l {
    --teaser-opulent-badge-right: #{$space-px-3};
    --teaser-opulent-badge-top: #{$space-3};
    --teaser-opulent-footer-grid-row: 4 / 5;
    --teaser-opulent-footer-padding-block: 0 #{$space-3};
    --teaser-opulent-grid-template-rows: repeat(4, auto);
    --teaser-opulent-image-wrapper-grid-row: 1 / 3;
    --teaser-opulent-link-grid-row: 2 / 3;
    --teaser-opulent-padding-inline: #{$space-px-3};
    --teaser-opulent-text-align: center;
    --teaser-opulent-text-grid-row: 3 / 4;
    --teaser-opulent-text-layer-grid-row: 3 / 5;
    --teaser-opulent-text-padding-block: #{$space-2} #{$space-5};
    --teaser-opulent__paid-marker--width: #{$space-4};
    --teaser-opulent__paid-marker--translate-y: 0.125em;

    --teaser-opulent-image-layer-background: linear-gradient(
        rgba(0, 0, 0, 0.05) 50%,
        rgba(0, 0, 0, 0.3) 100%
      ),
      linear-gradient(
        rgba(var(--teaser-opulent-bg-rgb), 0) 40%,
        rgba(var(--teaser-opulent-bg-rgb), 1) 95%
      ),
      radial-gradient(
        ellipse at top right,
        rgba(var(--teaser-opulent-bg-rgb), 0) 50%,
        rgba(var(--teaser-opulent-bg-rgb), 0.5) 100%
      );

    @include media-breakpoint-up(md) {
      --teaser-opulent-badge-right: #{$space-px-5};
      --teaser-opulent-badge-top: #{$space-5};
      --teaser-opulent-image-wrapper-grid-row: 1 / 4;
      --teaser-opulent-padding-inline: #{$space-px-5};
      --teaser-opulent-text-align: left;
      --teaser-opulent-text-layer-background: rgba(0, 0, 0, 0.5);
      --teaser-opulent-text-layer-grid-row: 4 / 5;
      --teaser-opulent-text-padding-block: #{$space-1_5} #{$space-2_5};
      --teaser-opulent__paid-marker--width: #{$space-5};

      --teaser-opulent-image-layer-background: linear-gradient(
          186deg,
          rgba(0, 0, 0, 0.1) 50%,
          rgba(0, 0, 0, 0.5) 86%
        ),
        linear-gradient(
          rgba(var(--teaser-opulent-bg-rgb), 0) 40%,
          rgba(var(--teaser-opulent-bg-rgb), 1) 95%
        ),
        radial-gradient(
          circle at top right,
          rgba(var(--teaser-opulent-bg-rgb), 0) 50%,
          rgba(var(--teaser-opulent-bg-rgb), 0.5) 100%
        );
    }

    @include media-breakpoint-up(lg) {
      --teaser-opulent-image-wrapper-grid-row: 1 / 5;
      --teaser-opulent-text-layer-background: rgba(0, 0, 0, 0);
      --teaser-opulent-text-padding-block: #{$space-2} #{$space-3};

      --teaser-opulent-image-layer-background: linear-gradient(
          204deg,
          rgba(0, 0, 0, 0.1) 46%,
          rgba(0, 0, 0, 0.6) 86%
        ),
        linear-gradient(
          rgba(var(--teaser-opulent-bg-rgb), 0) 40%,
          rgba(var(--teaser-opulent-bg-rgb), 1) 95%
        ),
        radial-gradient(
          circle at top right,
          rgba(var(--teaser-opulent-bg-rgb), 0) 50%,
          rgba(var(--teaser-opulent-bg-rgb), 0.5) 100%
        );

      .group-teaserblock--opulent-l & {
        --teaser-opulent-badge-right: #{$space-px-10};
        --teaser-opulent-badge-top: #{$space-8};
        --teaser-opulent-padding-inline: #{$space-px-10};
      }
    }
  }
}
