@use "../abstracts" as *;
@use "../foundation/icons";

.teaser {
  --teaser-gap: #{$space-2};
  --teaser__paid-marker--width: #{$space-3};
  --teaser__paid-marker--translate-y: 0.1em;

  display: var(--teaser-display, flex);
  flex-direction: var(--teaser-flex-direction, column);
  gap: var(--teaser-gap);
  position: relative;

  @media (forced-colors: active) {
    & {
      border: 1px solid var(--color-border-default);
    }
  }

  &--standard-l {
    --teaser-gap: #{$space-2_5};
    --teaser__paid-marker--translate-y: 0;
    --teaser__paid-marker--width: #{$space-3};

    @include media-breakpoint-up(md) {
      --teaser-gap: #{$space-3};
      --teaser__paid-marker--translate-y: 0.1em;
      --teaser__paid-marker--width: #{$space-5};

      .teaser-footer {
        min-height: $space-2;
      }

      .teaser-footer__bookmark {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    @include media-breakpoint-up(lg) {
      --teaser-gap: #{$space-4};
    }

    .teaser__headline-wrapper {
      text-wrap: unset;
    }
  }

  &--standard-m {
    @include media-breakpoint-up(md) {
      --teaser-gap: #{$space-3};
    }
  }

  &--standard-xs,
  &--embed {
    --teaser-flex-direction: row;
    --teaser-link-gap: #{$space-0_5};
    --teaser__paid-marker--width: #{$space-2_5};
    --teaser__paid-marker--translate-y: 0.05em;
  }

  /* On mobile the 'teaser--embed' looks like the teaser 'teaser--standard-xs'.
    It can only appear in 'main' of contents with type 'article' or 'gallery'. */
  &--embed {
    clear: both; // to prevent the teaser from floating next to the ad (currently `id="rectangle_1"`)
    margin-block: $space-5;

    @include media-breakpoint-up(md) {
      --teaser-gap: var(--article-column-gap);
      --teaser-link-gap: #{$space-1};
      --teaser__paid-marker--width: #{$space-4};
      --teaser__paid-marker--translate-y: 0.1em;

      margin: $space-8 var(--article-body-reverse-indent-1-column);
    }

    @include media-breakpoint-up(lg) {
      margin-block: calc($space-10 + $space-1);
    }
  }

  /* On mobile the 'teaser--list' looks like the teaser 'teaser--standard-m'.
    The teaser grid layout should behave like the 'teaserblock--standard-xs' layout. */
  &--list {
    --teaser-display: grid;
    --teaser-gap: #{$space-2} #{$space-3};
    --teaser__paid-marker--width: #{$space-4};
    --teaser__paid-marker--translate-y: 0.15em;

    grid-template-columns: repeat(auto-fill, minmax(min(320px, 100%), 1fr));

    @include media-breakpoint-up(md) {
      --teaser-gap: #{$space-3} #{$space-4};
    }

    @include media-breakpoint-up(lg) {
      --teaser-gap: #{$space-3} #{$space-5};
    }
  }

  &--topic-intro-section {
    padding: $space-5 $space-3;
    margin-bottom: $space-1_5;
    background: var(--color-bg-default);

    .teaser__text {
      margin-top: $space-2;
    }

    .teaser__button-read-more {
      margin-top: $space-2_5;
    }

    &.teaser--list {
      --teaser-gap: #{$space-4};

      @include media-breakpoint-up(md) {
        .teaser__content {
          order: -1;
        }
      }
    }

    @include media-breakpoint-up(md) {
      padding: $space-5;
      margin-bottom: $space-2;
    }

    @include media-breakpoint-up(lg) {
      padding: $space-5 $space-10;
      margin-bottom: $space-3;
    }

    .image-element__caption {
      display: none;
    }
  }

  &--topic-section {
    padding: $space-8 $space-3;
    background: var(--color-bg-default);

    @include media-breakpoint-up(md) {
      padding: $space-8 $space-5;
    }

    @include media-breakpoint-up(lg) {
      padding: $space-8 $space-10;
    }
  }

  &__button-read-more {
    display: flex;
    align-items: center;
    font-weight: $font-weight-500;
    text-decoration: none;
    color: var(--color-text-default);
    max-width: max-content;

    [class*="icon-"] {
      margin-left: $space-1;
      font-size: rem(13px);
    }
  }
}

.teaser__text {
  margin-top: $space-1;

  a {
    color: var(--color-text-default);
  }
}

.teaser__ad-labels {
  inset: auto auto calc(100% + $space-0_5) 0;
  margin-bottom: 0;
  padding-bottom: 0;
  position: absolute;
}

.teaser__time {
  display: none;
}

.teaser__image-wrapper {
  position: relative;

  :is(.teaser--standard-xs, .teaser--embed) & {
    flex: 0 0 rem(96px);
  }

  .teaser--embed & {
    @include media-breakpoint-up(md) {
      flex: 0 1 50%;
    }
  }
}

.teaser__badge {
  inset: $space-1_5 $space-1_5 auto auto;
  position: absolute;
}

.teaser__image img {
  width: 100%;
}

.teaser__image .image-element__caption {
  display: none;
}

.teaser__content {
  :is(.teaser--standard-xs, .teaser--embed) & {
    flex: 1 1 auto;
  }

  .teaser--embed & {
    @include media-breakpoint-up(md) {
      flex: 0 1 50%;
    }
  }

  .teaser--standard-l & {
    max-width: rem(680px);
  }
}

.teaser__link {
  color: inherit;
  text-decoration: none;

  // to make the whole teaser clickable
  &::after {
    position: absolute;
    content: "";
    inset: 0;
  }
}

.teaser__footer {
  margin-top: $space-1_5;

  :is(.teaser--standard-xs, .teaser--embed) & {
    margin-top: $space-1;
  }

  .teaser--embed & {
    @include media-breakpoint-up(md) {
      margin-top: $space-1_5;
    }
  }
}

/* Order: The color of the kicker should be defined before the default and the brand colors. */
.group-teaserblock--with-background-image {
  --teaser-kicker-color: var(--color-text-invert);
}

.teaser__kicker {
  color: var(--teaser-kicker-color, var(--color-text-highlight));

  &--capital {
    --teaser-kicker-color: var(--color-text-highlight-capital);
  }

  &--geo {
    --teaser-kicker-color: var(--color-text-highlight-geo);
  }
}

.teaser__headline-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--teaser-link-gap, $space-1);
  text-wrap: pretty;

  @supports (text-wrap: pretty) {
    @include media-breakpoint-up(md) {
      hyphens: auto;
    }
  }
}

/*
  'em' units were used to "automatically" adjust the position and the size of the stern+ icon
  if the font size (typo class) of the headline changes.
*/

.teaser__paid-marker {
  transform: translateY(var(--teaser__paid-marker--translate-y, 0));
  width: var(--teaser__paid-marker--width, $space-5);
}
