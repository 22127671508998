@use "../abstracts" as *;
@use "../foundation/icons";

.title {
  margin-bottom: $space-1_5;

  @include media-breakpoint-up(xl) {
    margin-bottom: $space-2;
  }

  .article--geo & {
    --color-kicker: var(--color-text-highlight-geo);
  }

  .article--capital & {
    --color-kicker: var(--color-text-highlight-capital);
  }

  .article--str_crime & {
    --color-kicker: var(--color-text-default);
  }

  .page-opulent & {
    margin-bottom: $space-3;
    text-align: center;
  }

  &__marketing {
    display: flex;
    gap: 0 $space-1;
    margin: 0 0 $space-2 0;

    @include media-breakpoint-up(md) {
      float: right;
      margin: 0 0 0 $space-1_5;
    }
  }

  &__kicker {
    color: var(--color-kicker, var(--color-text-highlight));
    display: block;
    margin-bottom: $space-1;

    .page-opulent & {
      color: var(--color-text-invert);
      margin-bottom: $space-2;
      text-shadow: $text-shadow-small;
    }
  }

  &__headline {
    .page-opulent & {
      text-shadow: $text-shadow-large;
    }
  }

  &__paid-marker {
    transform: translateY(var(--title__paid-marker--translate-y, 0.125em));
    width: var(--title__paid-marker--width, $space-5);

    .page-opulent & {
      --title__paid-marker--translate-y: 0.1em;
    }

    @include media-breakpoint-up(md) {
      --title__paid-marker--width: #{$space-7};

      .page-opulent & {
        --title__paid-marker--width: #{$space-5};
        --title__paid-marker--translate-y: 0.05em;
      }
    }

    @include media-breakpoint-up(lg) {
      .page-opulent & {
        --title__paid-marker--width: #{$space-7};
        --title__paid-marker--translate-y: 0.125em;
      }
    }

    @include media-breakpoint-up(xl) {
      --title__paid-marker--width: #{$space-8};
      --title__paid-marker--translate-y: 0.05em;

      .page-opulent & {
        --title__paid-marker--width: #{$space-7};
        --title__paid-marker--translate-y: 0.125em;
      }
    }
  }
}
