@use "../abstracts" as *;

.teaser-footer {
  align-items: center;
  color: var(--color-text-inset);
  display: flex;
  gap: $space-1_5;

  .group-teaserblock--with-background-image & {
    color: var(--color-text-invert-alpha);
  }

  &__brand-icon {
    height: $space-2;
  }

  &__ws-bookmarkbutton {
    margin-left: auto;
  }

  &__bookmark {
    align-items: center;
    aspect-ratio: 1;
    display: flex;
    font-size: $font-size-20;
    justify-content: center;
    margin-left: auto;
    padding: 0;
    position: relative;
    width: $space-2_5;
    z-index: 2; // to place it on top of the teaser `.teaser__link::after`

    .group-teaserblock--with-background-image & {
      color: var(--color-text-invert-alpha);
    }
  }
}
