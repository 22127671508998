@use "../abstracts" as *;

// Amount of visible items are taken from the design
// The amount of gaps (--gaps-amount) is always one less than the amount of visible items

.group-teaser-gallery {
  --gap: #{$space-3};
  --gaps-amount: 1;
  --group-teaser-gallery-padding-horizontal: #{$space-px-3};
  --group-teaser-gallery-padding-vertical: #{$space-4};
  --visible-items: 1.2;

  margin-bottom: var(--group-teaser-gallery-margin-bottom, $space-1_5);
  padding: var(--group-teaser-gallery-padding-vertical)
    var(--group-teaser-gallery-padding-horizontal);
  position: relative;

  &:not(.group-teaser-gallery--small) {
    background-color: var(--color-bg-secondary);
    box-shadow: $content-shadow;
  }

  @include media-breakpoint-up(md) {
    --gaps-amount: 2;
    --group-teaser-gallery-margin-bottom: #{$space-2};
    --group-teaser-gallery-padding-horizontal: #{$space-px-5};
    --group-teaser-gallery-padding-vertical: #{$space-5};
    --visible-items: 2.5;
  }

  @include media-breakpoint-up(lg) {
    --gaps-amount: 3;
    --group-teaser-gallery-padding-horizontal: #{$space-px-10};
    --group-teaser-gallery-padding-vertical: #{$space-8};
    --visible-items: 3.33;
  }

  &--small {
    margin-bottom: 0;
    padding-inline: 0;

    --gap: #{$space-1};
    --gaps-amount: 3;
    --visible-items: 3.33;

    @include media-breakpoint-up(md) {
      --gap: #{$space-1_5};
      --gaps-amount: 4;
      --visible-items: 5;
    }
  }

  &__items {
    margin-top: $space-3;
    position: relative;

    @media (hover: hover) {
      &:hover,
      &:focus-visible {
        --group-teaser-gallery-button-display: block;
      }
    }
  }

  &__scroller {
    display: flex;
    gap: var(--gap);
    margin: 0 calc(-1 * var(--group-teaser-gallery-padding-horizontal));
    overflow: auto hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &--small {
      margin-inline: 0;
    }

    @media (prefers-reduced-motion: reduce) {
      & {
        scroll-behavior: auto;
      }
    }
  }

  // The calculation of the width is based on the visible items:
  // It subtracts the horizontal margin of the first item and the total gap width (based on the count of visible items) from 100%.
  // The result is then divided by the number of visible items to find the width of each slide.
  // 100% - firstSlideMargin - amount of visible gaps * gap / visible items

  &__item {
    flex-shrink: 0;
    flex-basis: calc(
      (
          100% - var(--group-teaser-gallery-padding-horizontal) - var(
              --gaps-amount
            ) *
            var(--gap)
        ) /
        var(--visible-items)
    );

    &:first-child {
      margin-left: var(--group-teaser-gallery-padding-horizontal);
    }

    &--small {
      flex-basis: calc(
        (100% - var(--gaps-amount) * var(--gap)) / var(--visible-items)
      );

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__button {
    aspect-ratio: 1;
    background: var(
      --group-teaser-gallery-button-bg,
      var(--color-bg-invert-subtle)
    );
    border-radius: 50%;
    box-shadow: 0 2px 7px 0 var(--color-bg-invert-inset);
    color: var(--color-text-invert);
    display: var(--group-teaser-gallery-button-display, none);
    padding: 0;
    position: absolute;
    top: calc(50% - $space-2_5);
    transition: background-color var(--default-transition-time);
    width: $space-5;
    z-index: 4;

    &--prev {
      left: -$space-1_5;

      &-small {
        left: -12px;
      }
    }

    &--next {
      right: -$space-1_5;

      &-small {
        right: -12px;
      }
    }

    &:hover,
    &:focus-visible {
      --group-teaser-gallery-button-bg: var(--color-bg-invert);
      --group-teaser-gallery-button-display: block;
    }
  }

  &__button-icon {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}
