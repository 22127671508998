@use "../abstracts" as *;
@use "../foundation/icons";

.text-element {
  margin-bottom: $space-2_5;

  @include media-breakpoint-up(md) {
    margin-bottom: $space-3;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-4;
  }

  .article--geo & {
    --blockquote-color: var(--color-text-highlight-geo);
    --blockquote-icon-background: var(--color-bg-subtle);
  }

  .article--capital & {
    --blockquote-color: var(--color-text-highlight-capital);
    --blockquote-icon-background: var(--color-bg-subtle);
  }

  &--blockquote {
    margin: $space-5 0;

    @include media-breakpoint-up(md) {
      margin: $space-6 0;
    }

    @include media-breakpoint-up(lg) {
      margin: $space-8 0;
    }

    @include media-breakpoint-up(xl) {
      margin: $space-10 0;
    }

    blockquote {
      color: var(--blockquote-color, var(--color-text-highlight-variant));
      position: relative;

      &::before {
        @extend .icon-element;
        align-items: center;
        aspect-ratio: 1;
        background: var(
          --blockquote-icon-background,
          var(--color-bg-highlight-subtle)
        );
        border-radius: $space-0_5;
        content: $icon-quote;
        font-size: $font-size-24;
        justify-content: center;
        margin-bottom: $space-2_5;
        width: $space-5;

        @include media-breakpoint-up(md) {
          left: calc(
            -1 * ($space-5 + var(--gap, $space-2_5))
          ); // icon width + gap to text
          position: absolute;
          top: 0;
        }

        @include media-breakpoint-up(lg) {
          --gap: #{$space-4};
        }
      }
    }

    cite {
      display: block;
      font-style: normal;
      margin-block-start: $space-3;
    }

    .text-element__quote-by {
      color: var(--blockquote-color, var(--color-text-highlight-variant));
    }
  }

  &--justify {
    text-align: justify;
  }

  &--right {
    text-align: right;
  }

  &--center {
    text-align: center;
  }

  &--table {
    overflow-x: scroll;

    table {
      border-collapse: collapse;
      text-align: left;
      width: 100%;
    }

    tr:nth-child(even) {
      background: var(--color-bg-default);
    }

    tr:nth-child(odd) {
      background: var(--color-bg-subtle);
    }

    td,
    th {
      padding: $space-1_5 $space-0_5;
      vertical-align: top;

      @include media-breakpoint-up(lg) {
        padding: $space-2 calc($space-1_5 / 2);
      }

      &:first-child {
        border-radius: $space-1 0 0 $space-1;
        padding-left: $space-1_5;

        @include media-breakpoint-up(lg) {
          padding-left: $space-2;
        }
      }

      &:last-child {
        border-radius: 0 $space-1 $space-1 0;
        padding-right: $space-1_5;

        @include media-breakpoint-up(lg) {
          padding-right: $space-2;
        }
      }
    }

    th {
      font-weight: $font-weight-500;
    }
  }
}
