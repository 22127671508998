@use "../abstracts" as *;

.feature-bar {
  margin-bottom: $space-5;

  @include media-breakpoint-up(md) {
    margin-bottom: $space-6;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $space-8;
  }

  &__items {
    display: flex;
    align-items: center;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: $space-1_5;
    margin: $space-1 0;

    &:last-child {
      margin-left: auto;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    padding: $space-1_5 $space-2;
    border: 1px solid var(--color-border-default);
    border-radius: $border-radius-low;
    cursor: pointer;
    transition: all var(--default-transition-time) ease;
    gap: $space-1;

    [class*="icon-"] {
      font-size: $font-size-20;
    }

    &--no-text {
      justify-content: center;
      width: rem(44px);
      height: rem(44px);
      padding: 0;

      [class*="icon-"] {
        margin-right: 0;
      }

      &.change-bg {
        background: var(--color-bg-invert);

        [class*="icon-"] {
          color: var(--color-text-invert);
        }

        &.start-animation {
          background: var(--color-bg-default);
          transition: all 2s ease;

          [class*="icon-"] {
            color: var(--color-text-default);
            transition: all 2s ease;
          }
        }
      }
    }

    &:not(.change-bg, .start-animation) {
      @media (hover: hover) {
        &:hover,
        &:focus-visible {
          background-color: var(--color-bg-emphasized-subtle);
        }
      }

      &:active {
        background-color: var(--color-bg-emphasized-moderate);
      }
    }

    :is(.ws-bookmarkbutton, .ws-texttospeech)[active] & {
      background-color: var(--color-bg-invert);
      border-color: var(--color-bg-invert);
      color: var(--color-text-invert);

      @media (hover: hover) {
        &:hover,
        &:focus-visible {
          background-color: var(--color-bg-invert-subtle);
        }
      }

      &:active {
        background-color: var(--color-bg-invert-subtler);
      }
    }
  }

  &__player-container:has(ws-media) {
    margin-top: $space-2;
  }
}
